.blockheading {
  color: $brand-primary;
  font-size: 55px;
  margin: 45px 0;
}

.blockheading + div.blocksubheading {
  margin: -50px 0 70px;
}

.cycle-slideshow-wrapper {
  position: relative;

  .cycle-prev {
    position: absolute;
    left: -45px;
    top: 50%;
    display: block;
    width: 25px;
    margin-top: -30px;
    height: 60px;
    line-height: 60px;
    font-size: 60px;
    cursor: pointer;
  }

  .cycle-next {
    position: absolute;
    right: -45px;
    top: 50%;
    display: block;
    width: 25px;
    margin-top: -30px;
    height: 60px;
    line-height: 60px;
    font-size: 60px;
    cursor: pointer;
  }
}

.sticked-bottom {
  .wpb_content_element {
    margin-bottom: 0 !important;
  }
}

iframe {
  display: block;
}

