#topbar {
  padding: 7px 0;
  background-color: $background-black;
  color: #fff;
  font-size: 15px;
  line-height: 20px;

  a, button {
    color: #fff;
    text-decoration: none;
  }

  .lang-menu {
    text-align: center;
  }

  .login-btn {
    text-align: right;

    a {
      display: inline-block;
      margin-top: 3px;
    }
  }
}

#langbar {
  display: none;
  background-color: #373C41;
  text-align: center;
  font-size: 15px;
  line-height: 20px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
  }

  a {
    display: inline-block;
    padding: 18px 12px;
    color: #fff;
    text-transform: capitalize;
    -webkit-transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      color: #1464BE;
    }
  }
}

.navbar-brand {
  padding: 2px 0 0 15px;
  height: auto;

  img {
    display: block;
    height: auto;

    @media (max-width: #{$screen-md-max}) {
      margin-top: 11px;
      width: 140px;
    }
  }
}

#header {
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: none;

  & > .container {
    padding-top: 20px;
  }

  @media (min-width: #{$screen-md-min}) {
    &.sticky {
      width: 100%;
      z-index: 1000;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

    & > .container {
    position: relative;
  }



  .nav > li > a:focus, .nav > li > a:hover {
    background-color: transparent;
  }

  .navbar-right {
    a {
      font-size: 23px;
    }
  }


  #searchbutton {
    display: block;
    font-size: 0;
    height: 23px;
    width: 23px;
    background: url('../images/lupe_schwarz.svg') no-repeat 50% 50% / contain;
    margin: 15px 18px 15px 22px;
    padding: 0;

    &:hover {
      background-image: url('../images/lupe_blau.svg');
    }
  }

  #envelope-link a {
    display: block;
    font-size: 0;
    height: 25px;
    width: 25px;
    background: url('../images/mail_schwarz.svg') no-repeat 50% 50% / contain;
    margin: 13px 11px 15px 26px;
    padding: 0;

    &:hover {
      background-image: url('../images/mail_blau.svg');
    }
  }
}

#searchbar {
  display: none;
  margin: 0 0 20px;
  padding: 10px 0;
  background-color: $brand-primary;

  form {
    float: right;
  }
}

@media (max-width: #{$screen-xs-max}) {
  #header {
    padding-top: 5px;
  }
  .navbar-header {
    float: left;
  }

  .navbar-right {
    float: right;
    margin: 2px -15px 0 5px;

    li {
      display: inline-block !important;
    }
  }
}


#header .mega-menu-wrap {
  @media (max-width: #{$screen-sm-max}) {
    float: none;
  }


  .mega-menu-closer {
    &:hover {
      color: $brand-primary;
    }

    @media (max-width: #{$screen-sm-max}) {
      margin-top: -10px;
      margin-bottom: -10px;
    }

  }

  .mega-menu {
    & > li.mega-menu-item {
      & > a.mega-menu-link {
        @media (max-width: #{$screen-md-max}) {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
      }

      & > .mega-sub-menu {
        box-shadow: none !important;
      }
    }

    & > .mega-toggle-on > a, a:hover {
      color: $brand-primary !important;
    }
  }

  .mega-menu-toggle {
    margin-top: 12px;

    .mega-toggle-block:before {
      font-size: 30px;
    }
  }
}