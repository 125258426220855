.main > .wpb_row {
  margin: 45px 0 0;
}

.main > div:first-child {
  margin-top: 0;
}

.vc_row.inverted {
  background-color: $background-dark;
}

.header-inner {

  .wpb_single_image .vc_single_image-wrapper {
    width: 100%; 
  }

  .wpb_single_image .vc_figure {
    display: block;
  }

  img {
    width: 100%;
    display: block;
  }
}


.divider-arrow {
  margin: 0 !important;
  overflow: visible !important;
  height: 1px;
  z-index: 10;

  .vc_separator {
    margin: 0;
  }

  .vc_sep_line {
    border: none !important;
    background-color: $brand-primary !important;
    top: 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      left: 50%;
      margin-left: -20px;
      bottom: -40px;
      border-top-color: $brand-primary;
      z-index: 11;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      left: 50%;
      margin-left: -20px;
      bottom: -39px;
      border-top-color: #fff;
      z-index: 12;
    }
  }
}

.list-block {
  margin: 0 !important;
  padding: 70px 0;
  z-index: 1;

  .wpb_wrapper h2:first-child, .wpb_wrapper h3:first-child {
    margin-top: -5px;
  }
}

.list-block-gray, .list-block-gray + div + div .vc_sep_holder {
  background: -webkit-gradient(linear, left center, right center, from(#FFFFFF),color-stop(100%, #E8E6E6));
  background: -webkit-linear-gradient(left,#FFFFFF ,#E8E6E6 100%);
  background: linear-gradient(to right,#FFFFFF ,#E8E6E6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#FFFFFFFF, endColorstr=#FFE8E6E6, GradientType=1);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFE8E6E6', GradientType=1)";
}

.list-block-gray-reverse, .list-block-gray-reverse + div + div .vc_sep_holder {
  background: -webkit-gradient(linear, left center, right center, from(#E8E6E6),color-stop(100%, #FFFFFF));
  background: -webkit-linear-gradient(left, #E8E6E6, #FFFFFF 100%);
  background: linear-gradient(to right,#E8E6E6, #FFFFFF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#FFE8E6E6, endColorstr=#FFFFFFFF, GradientType=1);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFE8E6E6', endColorstr='#FFFFFFFF', GradientType=1)";
}

.list-block-gray + div, .list-block-gray + div + div, .list-block-gray-reverse + div + div {
  .vc_sep_line {
    &:before {
      border-top-color: #f3f3f3;
    }
  }
}

.list-block-gray + div + div {

}


.downloads-list-block {
  margin-top: 100px !important;
  margin-bottom: 80px !important;

  h2 {
    margin: 0 0 2px;
    background-color: #1464BE;
    display: block;
    padding: 20px 10px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
  }
}

.downloads-list {
  margin: 0 0 30px;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 2px;

    a {
      background-color: #000;
      display: block;
      padding: 20px 10px;
      text-align: center;
      color: #fff;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        background-color: #1464BE;
      }
    }
  }
}

.getintouch-btn button, .getintouch-btn a {
  background-color: #1464BE !important;
  font-weight: 700;
  font-size: 18px !important;
  color: #fff !important;
  min-width: 300px;

  &:hover {
    background-color: #053880 !important;
  }
}