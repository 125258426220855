html, body {
  font: 300 15px/20px "Roboto Condensed", sans-serif;
}

a {
  color: #000;

  &:hover, &:focus {
    color: $brand-primary;
  }

  *:focus {
    outline: none;
  }
}

.main {
  font: 300 23px/31px "Roboto Condensed", sans-serif;
}

.cycle-slideshow-wrapper img {
  display: block;
  max-width: 100%;
  height: auto;
}