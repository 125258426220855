#footer {
  background-color: #222221;
  color: #fff;

  padding-top: 40px;
  padding-bottom: 20px;

  h3 {
    font-size: 17px;
    font-weight: 700;
    margin: 11px 0 17px;
  }

  .vc_row.wpb_row {
    margin-bottom: 10px;
  }

  .footer-divider {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 14px;
      bottom: 0;
      width: 1px;
      background-color: #ffffff;
      left: 50%;
      margin-left: -1px;
    }
  }

  .vc_wp_custommenu {
    margin-bottom: 0;
  }
}

#social-links-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  li {
    display: inline-block;
  }

  a {
    display: block;
    width: 52px;
    height: 52px;
    font-size: 0;
    border-radius: 50%;
    border: 1px solid transparent;
  }
}

#footer-btns {
  width: 80%;
  margin: 36px 0 0 0;
  padding: 0;
  list-style: none;

  @media (max-width: #{$screen-xs-max}) {
    width: auto;
    margin: 36px 20px 0;
  }

  li {
    margin-bottom: 34px;
    font-weight: 700;
    font-size: 18px;

    a, span {
      padding: 15px 10px;
      display: block;
      border: 1px solid #fff;
      border-radius: 3px;
      text-align: center;
      color: #fff;

      &:hover {
        background-color: #1464BE;
        border-color: #1464BE;
        text-decoration: none;
      }
    }
  }
}

#footer .widget_nav_menu > ul {
  margin: 11px 0 0 0;
  padding: 0;
  list-style: none;

  & > li {

    @media (min-width: #{$screen-sm-min}) {
      &:nth-last-child(-n+3) ul {
        margin-bottom: 0;
      }
    }

    @media (max-width: #{$screen-xs-max}) {
      &:nth-child(odd) {
        clear: both;
      }
    }


    & > a {
      font-size: 17px;
      font-weight: 700;
      color: #fff;
    }
  }

  ul {
    margin: 15px 0 40px;
    padding: 0;
    list-style: none;

    a {
      color: #fff;
      font-size: 14px;
      display: block;
      padding: 2px 0 2px 15px;

      @media (max-width: #{$screen-md-max}) {
        font-size: 13px;
        padding: 2px 0;
      }

      i {
        font-size: 17px;
        margin-right: 10px;
      }

      &:hover {
        background-color: #1464BE;
        text-decoration: none;
      }
    }
  }
}

.soc-fb {
  background: url('../images/smi/outline/facebook.svg') no-repeat 50% 50%;
  &:hover {
    background-image: url('../images/smi/filled/facebook.svg');
    background-color: #385699;
    border-color: #385699 !important;
  }
}

.soc-tw {
  background: url('../images/smi/outline/twitter.svg') no-repeat 50% 50%;
  &:hover {
    background-image: url('../images/smi/filled/twitter.svg');
    background-color: #22cbff;
    border-color: #22cbff !important;
  }
}

.soc-li {
  background: url('../images/smi/outline/linkedin.svg') no-repeat 50% 50%;
  &:hover {
    background-image: url('../images/smi/filled/linkedin.svg');
    background-color: #007bb6;
    border-color: #007bb6 !important;
  }
}

.soc-xi {
  background: url('../images/smi/outline/xing.svg') no-repeat 50% 50%;
  &:hover {
    background-image: url('../images/smi/filled/xing.svg');
    background-color: #005d5e;
    border-color: #005d5e !important;
  }
}

.soc-gp {
  background: url('../images/smi/outline/googleplus.svg') no-repeat 50% 50%;
  &:hover {
    background-image: url('../images/smi/filled/googleplus.svg');
    background-color: #385699;
    border-color: #385699 !important;
  }
}

.soc-yt {
  background: url('../images/smi/outline/youtube.svg') no-repeat 50% 50%;
  &:hover {
    background-image: url('../images/smi/filled/youtube.svg');
    background-color: #de4931;
    border-color: #de4931 !important;
  }
}

#copyright {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  line-height: 20px;

  @media (max-width: #{$screen-sm-max}) {
    margin-top: 30px;
  }
}
.copyright-nav {
  margin: 0 0 0 50px;
  padding: 0;
  list-style: none;
  display: inline-block;

  @media (max-width: #{$screen-sm-max}) {
    margin: 0;
    display: block;
  }

  li {
    display: inline-block;

    @media (max-width: #{$screen-xs-max}) {
      display: block;
      width: 50%;
      float: left;
    }
  }

  a {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    padding: 2px 10px;

    &:hover {
      background-color: #1464BE !important;
    }
  }
}

