.success-slider {
  position: relative;
  h2 {
    margin: 53px 0 0 0;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 45px;
  }

  #rev_slider_2_2_wrapper {
    padding-bottom: 50px;
    padding-top: 125px !important;

    .tp-bullet {
      width: 27px;
      height: 27px;
      border-width: 1px;
    }

  }
}

.gridromtag-wrapper {
  margin-top: 67px;
}

.gridromtag-item {
  display: block;

  span {
    display: block;
  }
  .img-txt {
    background-color: rgba(0,0,0,.75);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    opacity: 0;
    transition: all 0.5s;
  }

  .img-title {
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 30px;
  }

  .img-subtitle {
    font-weight: 400;
    font-size: 24px;
  }

  .img {
    display: table;
    height: 262px;
    width: 100%;
    background-size: auto 100%;
    background-position: 50% 50%;
    position: relative;
    transition: all 0.5s;
  }

  &:hover {
    color: #000;
    text-decoration: none;
    transition: all 0.5s;

    .img {
      background-size: auto 120%;
      transition: all 0.5s;
    }
    .img-txt {
      transition: all 0.5s;
      opacity: 1;
    }
  }


  .hdr {
    margin: 35px 0 10px;
    text-transform: uppercase;
    font-size: 26px;
    color: #1464BE;
    font-weight: 400;

    &:after {
      margin-left: 20px;
      content:"\2192";
    }
  }

  .desc {
    font-size: 19px;
    line-height: 27px;
  }
}

.vc_tta.vc_general {
  h4.vc_tta-panel-title {
    font-size: 26px;
    a, a span {
      color: $brand-primary;
    }
  }

  .vc_tta-panel-body {
    padding: 30px 26px !important;
    font-size: 19px;
    line-height: 23px;

    p {
      margin-bottom: 28px;
    }
  }

  .vc_btn3 {
    border-color: $brand-primary;
    color: $brand-primary;
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 11px 19px;

    &:hover {
      border-color: $brand-primary;
      background-color: $brand-primary;
      color: #fff;
    }

    @media (min-width: #{$screen-sm-min}) {
      min-width: 365px;
    }

  }
}

.morejobs .vc_btn3 {
  border-color: $brand-primary !important;
  color: $brand-primary !important;
  font-size: 21px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 11px 19px;

  &:hover {
    border-color: $brand-primary !important;
    background-color: $brand-primary !important;
    color: #fff !important;
  }

  @media (min-width: #{$screen-sm-min}) {
    min-width: 365px;
  }
}
.referenzen-slider {
  margin-top: 80px;
  margin-bottom: 90px;

  .wpb_wrapper {
    margin: 0 50px;

  }
}

.frontpage-hero {
  font: 300 19px/27px "Roboto Condensed", sans-serif;
}