// Search form
.search-form {
  @extend .form-inline;

  label {
    font-weight: normal;
    @extend .form-group;
  }

  .search-field {
    @extend .form-control;
    font-size: 20px;
    border-radius: 5px;
  }

  .search-submit {
    @extend .btn;
    background-color: $brand-primary;
    border: 1px solid #fff;
    font-size: 20px;
    color: #ffffff;
    border-radius: 5px;
    padding: 2px 10px;
    text-transform: uppercase;
    margin-left: 5px;

    &:before {
      content:"\2192";
      margin-right: 8px;
    }

    &:hover {
      background-color: #053880;
      color: #fff;
    }
  }
}

.wpcf7-form {
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 80px;


  input, textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid $brand-primary;
  }

  textarea {
    border: 1px solid $brand-primary;

    @media (max-width: #{$screen-sm-max}) {
      height: 200px;
    }
  }

  label {
    font-weight: 400;
    color: $brand-primary;
  }

  input[type=checkbox], input[type=radio] {
    width: auto;
  }

  .wpcf7-radio, .wpcf7-checkbox {
    .wpcf7-list-item {
      display: block;
    }
  }

  input[type=button], input[type=submit] {
    width: auto;
    background-color: #1464BE !important;
    font-size: 20px;
    font-weight: 700;
    color: #fff !important;
    min-width: 300px;
  }

  input[type=submit] {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

div.wpcf7-validation-errors {
  border: none !important;
  color: #f00;
  font-weight: bold;
}